import axios from "axios";

import { BaseUrl } from "../../constant/BaseUrl";

export default async (payload) => {
  if (payload && payload.token && payload.token.length > 0) {
    try {
      const resp = await axios({
        url: `${BaseUrl}/api${payload.route}`,
        method: "POST",
        headers: {
          authorization: !payload ? "" : !payload.token ? "" : payload.token,
        },
        data: payload.data,
      });
      return resp.data;
    } catch (error) {
      return {
        success: false,
        paymentError: error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : error,
      };
    }
  }
};
