/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint no-lone-blocks: 0 */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { storageStr } from "../constant/LocalStoreConstant";
import SidebarNavList from "../helpers/SidebarNavList";
import storeToken from "../helpers/LocalStorageSet";
// import OtherCoursesDropdown from "./OtherCoursesDropdown";
import ZhEnBtn from "./ZhEnBtn";
import RegisterBtn from "./RegisterBtn";
import Spinner from "../shared/Spinner";
import { setCurrToken } from "../reducers/Auth.Action";

const SideDrawer = ({
  classes,
  currLang,
  theme,
  open,
  navIntlText,
  handleDrawerClose,
  currToken,
  userRole,
  setCurrToken,
}) => {
  const [navList, setNavList] = useState([]);

  useEffect(() => {
    navIntlText &&
      Object.keys(navIntlText).length > 0 &&
      setNavList(SidebarNavList(navIntlText, currToken, userRole));

    const tokenInLocalStorage = localStorage.getItem(storageStr.utk);
    if (
      currToken === "" &&
      tokenInLocalStorage &&
      tokenInLocalStorage.length > 0
    ) {
      setCurrToken(tokenInLocalStorage);
    }
    if (
      currToken &&
      currToken.length > 0 &&
      (!tokenInLocalStorage || tokenInLocalStorage === "")
    ) {
      storeToken(storageStr.utk, currToken);
    }
    if (currToken === "" && !tokenInLocalStorage) {
      setCurrToken("RESET_INIT_AUTH");
    }
    // return () => { cleanup; };
  }, [navIntlText, currToken]);

  if (navList.length === 0) {
    return <Spinner />;
  }
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {navList.map((el, idx) => {
          if (!el.hasOwnProperty("otherCourses")) {
            if (el.key === "Menu-InitPage") {
              return (
                <ListItem button key={`${idx}_${el.key}`}>
                  <NavLink
                    className="appnav-link"
                    to={el.to}
                    onClick={handleDrawerClose}
                    exact
                  >
                    {el.word}
                  </NavLink>
                </ListItem>
              );
            } else {
              return (
                el.to !== "/fbUserManagement" && (
                  <ListItem button key={`${idx}_${el.key}`}>
                    <NavLink
                      className="appnav-link"
                      to={el.to}
                      onClick={handleDrawerClose}
                    >
                      {el.word}
                    </NavLink>
                  </ListItem>
                )
              );
            }
          } else {
            {
              /* return (
              <div className="float-right" key={`${idx}_MenuFor_SubCourses`}>
                <OtherCoursesDropdown otherCourses={el.otherCourses} />
              </div>
            ); */
            }
            return null;
          }
        })}
      </List>
      <ZhEnBtn currLang={currLang} />
      <RegisterBtn navIntlText={navIntlText} />
    </Drawer>
  );
};

SideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  currLang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  navIntlText: PropTypes.object.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  currToken: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  setCurrToken: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Authen: { currToken, userRole } }) => ({
  currToken,
  userRole,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrToken: (token) => dispatch(setCurrToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
