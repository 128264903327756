import React from "react";
// import PropTypes from "prop-types";
import { BrowserRouter as BwsRouter } from "react-router-dom";

// import AppNavbar from './Navbar/_Old_Navbar';
import Navigation from "./navigation";
import RootRouters from "./routes";

const App = () => {
  return (
    <BwsRouter>
      <Navigation />
      <RootRouters />
    </BwsRouter>
  );
};

/* App.propTypes = {
	refetch: PropTypes.func.isRequired,
	session: PropTypes.object.isRequired,
}; */

export default App;
