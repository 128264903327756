import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
// import DialogContentText from "@material-ui/core/DialogContentText";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { BaseUrl } from "../constant/BaseUrl";
import { storageStr } from "../constant/LocalStoreConstant";
import { UserRegisterModal } from "../constant/UserRegisterModal";
import storeToken from "../helpers/LocalStorageSet";
import Otp from "../components/Otp";
import { setCurrToken } from "../reducers/Auth.Action";

const UserRegisterForm = ({
  chosenLang,
  Login_signup_modal_Lang,
  button_Lang,
  password_hints,
  error_message,
  setCurrToken,
  whatModalIsThisUserAction,
  states: { userFormVals, isSubmitted, otpShow, otpCode },
  stateFuncs: {
    setUserFormVals,
    setIsSubmitted,
    setOtpShow,
    setToggleAlert,
    setOtpCode,
  },
  handleCancel,
  history,
}) => {
  const userRegisterFormRef = useRef(Symbol("User_Register_Form"));

  const [formBtnDisabled, setFormBtnDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule("minLength2_maxLength40", (value) => {
      if (value.length < 2 || value.length > 40) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("minLength1_maxLength20", (value) => {
      if (value.length < 1 || value.length > 20) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.hasValidationRule("minLength2_maxLength40") &&
        ValidatorForm.removeValidationRule("minLength2_maxLength40");
      ValidatorForm.hasValidationRule("minLength1_maxLength20") &&
        ValidatorForm.removeValidationRule("minLength1_maxLength20");
    };
  }, []);

  const handleChangeFormVal = (e) => {
    const { name, value } = e.target;
    setUserFormVals({
      ...userFormVals,
      [name]: value,
    });
  };

  const validatorListener = (result) => {
    setFormBtnDisabled(!result);
  };

  const getOtpCode = async () => {
    try {
      /* const aaa = await axios.get(`https://hiwhitecardbackend.com`);
      if (aaa) {
        console.log("85 -- aaa: ", aaa);
      } */
      setIsSubmitted(true);
      const { data } = await axios.post(`${BaseUrl}/api/getcode`, {
        phonenumber: userFormVals.countryCode + userFormVals.pno,
        channel: "sms",
      });
      data.status === "pending" && !data.valid && setOtpShow(true);
    } catch (error) {
      setIsSubmitted(false);
      setToggleAlert({
        isShow: true,
        grimness: "error",
        msg: error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : error,
      });
      // if no internet connection, then close modal
      if (error.message && error.message === "Network Error") {
        handleCancel();
      }
    }
  };

  const verifyRegisterUser = async () => {
    setIsLoading(true);
    const url =
      whatModalIsThisUserAction === UserRegisterModal.login
        ? `${BaseUrl}/api/signin`
        : `${BaseUrl}/api/register`;
    const reqObj =
      whatModalIsThisUserAction === UserRegisterModal.login
        ? {
            countryCode: userFormVals.countryCode,
            pno: userFormVals.pno,
            password: userFormVals.password,
            status: userFormVals.status,
            phonenumber: userFormVals.countryCode + userFormVals.pno,
            otpCode,
          }
        : {
            ...userFormVals,
            phonenumber: userFormVals.countryCode + userFormVals.pno,
            otpCode,
          };
    try {
      const { data } = await axios.post(url, reqObj);
      if (data.success) {
        storeToken(storageStr.utk, data.token);
        setCurrToken(data.token);
        handleCancel();
        history.push("/");
      } else {
        setToggleAlert({
          isShow: true,
          grimness: "error",
          msg: data.message
            ? data.message
            : "User information provided is not all correct.",
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setToggleAlert({
        isShow: true,
        grimness: "error",
        msg: error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : error,
      });
    }
  };

  return (
    <ValidatorForm
      name="User_Register_Form"
      ref={userRegisterFormRef}
      noValidate
      onSubmit={!isSubmitted ? getOtpCode : verifyRegisterUser}
    >
      <DialogContent>
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}
        {whatModalIsThisUserAction === UserRegisterModal.signup && (
          <>
            <TextValidator
              autoFocus
              name="firstName"
              value={userFormVals.firstName}
              margin="dense"
              label={Login_signup_modal_Lang.firstName}
              type="text"
              fullWidth
              onChange={handleChangeFormVal}
              validators={
                chosenLang === "en"
                  ? ["required", "minLength2_maxLength40"]
                  : ["required", "minLength1_maxLength20"]
              }
              errorMessages={
                chosenLang === "en"
                  ? [error_message.required, error_message.length2to40]
                  : [error_message.required, error_message.length1to20]
              }
              validatorListener={validatorListener}
            />
            <TextValidator
              name="lastName"
              value={userFormVals.lastName}
              margin="dense"
              label={Login_signup_modal_Lang.lastName}
              type="text"
              fullWidth
              onChange={handleChangeFormVal}
              validators={
                chosenLang === "en"
                  ? ["required", "minLength2_maxLength40"]
                  : ["required", "minLength1_maxLength20"]
              }
              errorMessages={
                chosenLang === "en"
                  ? [error_message.required, error_message.length2to40]
                  : [error_message.required, error_message.length1to20]
              }
              validatorListener={validatorListener}
            />
            <TextValidator
              name="email"
              value={userFormVals.email}
              margin="dense"
              label={Login_signup_modal_Lang.email}
              type="email"
              fullWidth
              onChange={handleChangeFormVal}
              validators={["required", "isEmail"]}
              errorMessages={[error_message.required, error_message.email]}
              validatorListener={validatorListener}
              disabled={isSubmitted}
            />
            <FormHelperText>
              {Login_signup_modal_Lang.uniqueVal}
              {Login_signup_modal_Lang.email}
            </FormHelperText>
          </>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              name="countryCode"
              value={userFormVals.countryCode}
              label={Login_signup_modal_Lang.cyCode}
              type="text"
              aria-readonly={true}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <TextValidator
              name="pno"
              value={userFormVals.pno}
              label={Login_signup_modal_Lang.mobile}
              type="text"
              fullWidth
              onChange={handleChangeFormVal}
              validators={["required", "matchRegexp:^[0]*4[0-9]{8}$"]}
              errorMessages={[error_message.required, error_message.mobile]}
              validatorListener={validatorListener}
              disabled={isSubmitted}
            />
            <FormHelperText>
              {Login_signup_modal_Lang.uniqueVal}
              {Login_signup_modal_Lang.mobile}
            </FormHelperText>
          </Grid>
        </Grid>
        <TextValidator
          name="password"
          value={userFormVals.password}
          margin="dense"
          label={Login_signup_modal_Lang.password}
          type="password"
          fullWidth
          onChange={handleChangeFormVal}
          // helperText="Example: eXample#3677"
          // ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,27})
          validators={[
            "required",
            "matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12})",
          ]}
          errorMessages={[error_message.required, error_message.password]}
          validatorListener={validatorListener}
        />
        {whatModalIsThisUserAction === UserRegisterModal.signup &&
          password_hints.map((el, idx) => (
            <FormHelperText key={el + idx}>{el}</FormHelperText>
          ))}
        {otpShow && (
          <Grid item xs={12} className="mt-1rem">
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              {Login_signup_modal_Lang.verifyOtp}
            </Typography>
            <Otp
              helperText={Login_signup_modal_Lang.inputOtpCode}
              otp={otpCode}
              setOtp={(val) => {
                setOtpCode(val);
              }}
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {button_Lang.Cancel}
        </Button>
        {/* has submit, then validate true, then show login/signup getOtpCode */}
        {!isSubmitted ? (
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitted || formBtnDisabled}
          >
            {button_Lang.GetCode}
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={formBtnDisabled || otpCode.length !== 6}
          >
            {whatModalIsThisUserAction === UserRegisterModal.login
              ? Login_signup_modal_Lang.login
              : Login_signup_modal_Lang.signup}
            {isLoading && <CircularProgress size="1rem" />}
          </Button>
        )}
      </DialogActions>
    </ValidatorForm>
  );
};

UserRegisterForm.propTypes = {
  chosenLang: PropTypes.string.isRequired,
  Login_signup_modal_Lang: PropTypes.object,
  button_Lang: PropTypes.object,
  password_hints: PropTypes.array,
  error_message: PropTypes.object,
  setCurrToken: PropTypes.func.isRequired,
  whatModalIsThisUserAction: PropTypes.string.isRequired,
  states: PropTypes.object.isRequired,
  stateFuncs: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  Lang: {
    chosenLang,
    Login_signup_modal_Lang,
    button_Lang,
    password_hints,
    error_message,
  },
}) => ({
  chosenLang,
  Login_signup_modal_Lang,
  button_Lang,
  password_hints,
  error_message,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrToken: (token) => dispatch(setCurrToken(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRegisterForm));
