import { LoginoutSignup } from "../constant/RouteRelated";
import { GetIntlNavObjKey } from "./GetIntlNavObjKey";

export const SetLoginSingupOrLogout = (
  langLoginoutSignup,
  userToken,
  fbLoggedIn
) => {
  return LoginoutSignup.map((el) => {
    if (el.key.includes("Logout")) {
      if (
        (userToken && userToken.length > 0) ||
        (fbLoggedIn && fbLoggedIn.includes("bravo"))
      ) {
        return {
          ...el,
          word: langLoginoutSignup[GetIntlNavObjKey(langLoginoutSignup, el)],
        };
      } else {
        return null;
      }
    } else {
      if (userToken === "" && fbLoggedIn === "") {
        return {
          ...el,
          word: langLoginoutSignup[GetIntlNavObjKey(langLoginoutSignup, el)],
        };
      } else {
        return null;
      }
    }
    /* el.key.includes("Logout")
            ? this.props.userSession.getCurrentUser &&
              Object.keys(this.props.userSession.getCurrentUser).length > 0
                ? {
                        ...el,
                        word:
                            intlNavText[GetIntlNavObjKey(intlNavText, el)],
                  }
                : null
            : this.props.userSession.getCurrentUser
            ? null
            : {
                    ...el,
                    word: intlNavText[GetIntlNavObjKey(intlNavText, el)],
              } */
  }).filter((el) => el);
};
