import { RECORD_FB_USER_SIGNIN, CLEAR_LOGGEDIN_FBUSER } from "../types";

const fbuserInitState = {
	fbLoggedIn: "",
};

export default (state = fbuserInitState, { type, payload }) => {
	switch (type) {
		case RECORD_FB_USER_SIGNIN:
			return {
				...state,
				fbLoggedIn: payload,
			};
		case CLEAR_LOGGEDIN_FBUSER:
			return {
				...state,
				fbLoggedIn: "",
			};
		default:
			return state;
	}
};
