import {
  GRAB_INITIAL_BROWSER_LANGUAGE /* ,
  SWITCH_RECTO_VOCABULARY */,
} from "../types";
import { ReactIntlAutoPickLocalLanguage } from "../helpers/ChooseLang";
import PickLangPortion from "../helpers/DesignateLangs";

const initialLangState = {
  chosenLang: "",
  navigation_name: {},
  button_text: {},
};

export default (state = initialLangState, { type, payload }) => {
  switch (type) {
    case GRAB_INITIAL_BROWSER_LANGUAGE:
      const chosenLang = !payload
        ? ReactIntlAutoPickLocalLanguage()
        : !payload.langID
        ? ReactIntlAutoPickLocalLanguage()
        : payload.langID;
      const vocabulary = PickLangPortion(
        chosenLang,
        !payload ? null : !payload.chunk ? null : payload.chunk
      );
      return {
        ...state,
        chosenLang,
        ...vocabulary,
      };
    /* case SWITCH_RECTO_VOCABULARY:
      const { langID, chunk } = payload;
      const vocabulary = PickLangPortion(langID, chunk);
      return {
        ...state
      }; */
    default:
      return state;
  }
};
