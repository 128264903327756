import { RECEIVE_PAYMENT_RESULT, CLEAR_PAYMENT_STATE } from "../types";

const initialPayState = {
  paymentSuccess: "",
  paymentError: "",
};

export default (state = initialPayState, { type, payload }) => {
  switch (type) {
    case RECEIVE_PAYMENT_RESULT:
      if (!payload.success) {
        return {
          ...initialPayState,
          paymentSuccess: "",
          paymentError: !payload
            ? ""
            : !payload.paymentError
            ? payload.message
              ? payload.message
              : ""
            : payload.paymentError,
        };
      } else {
        return {
          ...state,
          paymentSuccess: !payload
            ? ""
            : payload.message
            ? payload.message
            : "",
          paymentError: "",
        };
      }
    case CLEAR_PAYMENT_STATE:
      return {
        paymentSuccess: "",
        paymentError: "",
      };
    default:
      return state;
  }
};
