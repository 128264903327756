import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import { LanguageOpts } from "../constant/LanguageOpts";
import { SetBrowserLanguage } from "../reducers/Lang.Action";

const ZhEnBtn = ({ currLang, SetBrowserLanguage }) => (
  <Button
    color="primary"
    size="small"
    onClick={() => {
      localStorage.setItem(
        "langID",
        currLang === "en" ? LanguageOpts.zhCode : LanguageOpts.enCode
      );
      SetBrowserLanguage({
        langID: currLang === "en" ? LanguageOpts.zhCode : LanguageOpts.enCode,
      });
    }}
  >
    {currLang === "en" ? LanguageOpts.zh : LanguageOpts.en}
  </Button>
);

ZhEnBtn.propTypes = {
  currLang: PropTypes.string.isRequired,
  SetBrowserLanguage: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  SetBrowserLanguage: (langPiece) => dispatch(SetBrowserLanguage(langPiece)),
});

export default connect(null, mapDispatchToProps)(ZhEnBtn);
