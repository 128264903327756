/* eslint react-hooks/exhaustive-deps: 0 */
import { lazy, Suspense, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { RouteLinks } from "../constant/RouteRelated";
import AlertInfor from "../shared/AlertInfor";
import Spinner from "../shared/Spinner";
import { userConfirmOrDisagreeLogout } from "../reducers/Modal.Action";
// import PrivateRouteOtherUser from "./PrivateRouteOtherUser";
import LandingWhiteCard from "../pages/LandingWhiteCard";
/* import Rpl from "../container/Rpl";
import FbUserContainer from "../container/FbUserContainer"; */
const Gallery = lazy(() => import("../pages/Gallery"));
const Intro = lazy(() => import("../pages/Intro"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const UserManagement = lazy(() => import("../pages/UserManagement"));
const AdminManagement = lazy(() => import("../pages/AdminManagement"));
const Tile = lazy(() => import("../pages/Tile"));
const Waterproof = lazy(() => import("../pages/Waterproof"));
const NoFoundCompo = lazy(() => import("../shared/NoFound"));

const initAlert = {
  isShow: false,
  grimness: "error",
  msg: "",
};

const RootRoutes = ({
  currToken,
  userRole,
  coursesError,
  paymentSuccess,
  paymentError,
  shouldLogout,
  userConfirmOrDisagreeLogout,
}) => {
  const [toggleAlert, setToggleAlert] = useState(initAlert);

  useEffect(() => {
    if (
      coursesError &&
      coursesError.length > 0 &&
      typeof coursesError === "string" &&
      coursesError !== "Failed to verify token."
    ) {
      setToggleAlert({
        isShow: true,
        grimness: "error",
        msg: coursesError,
      });
    }
    if (
      paymentError &&
      paymentError.length > 0 &&
      typeof paymentError === "string" &&
      paymentError !== "Failed to verify token."
    ) {
      setToggleAlert({
        isShow: true,
        grimness: "error",
        msg: paymentError,
      });
    }
    if (
      paymentSuccess &&
      paymentSuccess.length > 0 &&
      typeof paymentSuccess === "string"
    ) {
      setToggleAlert({
        isShow: true,
        grimness: "success",
        msg: paymentSuccess,
      });
    }
    if (
      coursesError === "Failed to verify token." ||
      paymentError === "Failed to verify token."
    ) {
      userConfirmOrDisagreeLogout(true);
    }
    // return () => {cleanup}
  }, [coursesError, paymentSuccess, paymentError]);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* <Route path={RouteLinks.arci}  /> */}
        <Route exact path={RouteLinks.initLand} component={LandingWhiteCard} />
        <Route path={RouteLinks.gallery} render={() => <Gallery />} />
        <Route path={RouteLinks.intro} render={() => <Intro />} />
        <Route path={RouteLinks.approach} render={() => <ContactUs />} />
        {!!currToken && (
          <Route
            path={RouteLinks.management}
            render={() =>
              userRole === "student" ? <UserManagement /> : <AdminManagement />
            }
          />
        )}
        {/* <PrivateRouteOtherUser
          path={RouteLinks.fbUserManagement}
          component={FbUserContainer}
          otherLoggedIn={fbLoggedIn}
        /> */}
        <Route path={RouteLinks.tile} render={() => <Tile />} />
        <Route path={RouteLinks.waterproof} render={() => <Waterproof />} />
        <Route render={() => <NoFoundCompo />} />
      </Switch>
      <AlertInfor {...toggleAlert} setShowFunc={setToggleAlert} />
    </Suspense>
  );
};

RootRoutes.propTypes = {
  currToken: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  coursesError: PropTypes.string.isRequired,
  paymentSuccess: PropTypes.string.isRequired,
  paymentError: PropTypes.string.isRequired,
  shouldLogout: PropTypes.bool.isRequired,
  userConfirmOrDisagreeLogout: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  FbUsers: { fbLoggedIn },
  Authen: { currToken, userRole },
  Courses: { coursesError },
  Payment: { paymentSuccess, paymentError },
  ModalRdx: { shouldLogout },
}) => ({
  fbLoggedIn,
  currToken,
  userRole,
  coursesError,
  paymentSuccess,
  paymentError,
  shouldLogout,
});
const mapDispatchToProps = (dispatch) => ({
  userConfirmOrDisagreeLogout: (isLogout) =>
    dispatch(userConfirmOrDisagreeLogout(isLogout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootRoutes);
