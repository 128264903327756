import axios from "axios";

import { BaseUrl, apiRoutes } from "../../constant/BaseUrl";

export default async (payload) => {
  if (payload && payload.token && payload.token.length > 0) {
    const commonItems = {
      url: `${BaseUrl}/api${payload.route}`,
      method: "POST",
      headers: {
        authorization: !payload ? "" : !payload.token ? "" : payload.token,
      },
    };
    try {
      const resp = await axios(
        payload.route === apiRoutes.twoMonths
          ? commonItems
          : {
              ...commonItems,
              data: {
                email: payload.email,
              },
            }
      );
      return resp.data;
    } catch (error) {
      return {
        success: false,
        coursesError: error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : error,
      };
    }
  }
};
