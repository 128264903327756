import {
  OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL,
  OPEN_OR_CLOSE_LOGOUT_MODAL,
  CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL,
  RESET_MODAL_REDUCER_DATA,
} from "../types";

export const openCloseSignupLoginModal = (payload) => ({
  type: OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL,
  payload,
});

export const openCloseLogoutModal = (payload) => ({
  type: OPEN_OR_CLOSE_LOGOUT_MODAL,
  payload,
});

export const userConfirmOrDisagreeLogout = (payload) => ({
  type: CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL,
  payload,
});

export const resetModalReducer = () => ({
  type: RESET_MODAL_REDUCER_DATA,
});
