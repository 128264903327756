import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";

import { BaseUrl } from "../constant/BaseUrl";
import { UserRegisterModal } from "../constant/UserRegisterModal";
import { storageStr } from "../constant/LocalStoreConstant";
import { SetLoginSingupOrLogout } from "../helpers/LoginoutBtns";
import storeToken from "../helpers/LocalStorageSet";
import {
  openCloseSignupLoginModal,
  openCloseLogoutModal,
  resetModalReducer,
} from "../reducers/Modal.Action";
import { setCurrToken } from "../reducers/Auth.Action";
import { clearPayProcessState } from "../reducers/Payment.Action";
import { clearCurriculumState } from "../reducers/Courses.Action";

const RegisterBtn = ({
  currToken,
  userEmail,
  shouldLogout,
  setCurrToken,
  navIntlText,
  openCloseSignupLoginModal,
  openCloseLogoutModal,
  resetModalReducer,
  clearPayProcessState,
  clearCurriculumState,
  history,
}) => {
  const [currUserToken, setCurrUserToken] = useState("");

  useEffect(() => {
    // currToken && currToken.length > 0 && setCurrUserToken(currToken);
    const tokenInLocalStorage = localStorage.getItem(storageStr.utk);
    if (
      currToken === "" &&
      tokenInLocalStorage &&
      tokenInLocalStorage.length > 0
    ) {
      setCurrUserToken(tokenInLocalStorage);
      setCurrToken(tokenInLocalStorage);
    }
    if (
      currToken &&
      currToken.length > 0 &&
      (!tokenInLocalStorage || tokenInLocalStorage === "")
    ) {
      storeToken(storageStr.utk, currToken);
    }
    if (currToken === "" && !tokenInLocalStorage) {
      setCurrUserToken("");
      setCurrToken("RESET_INIT_AUTH");
      resetModalReducer();
      clearPayProcessState();
      clearCurriculumState();
    }
    const logoutWhenNeeded = async () => {
      resetModalReducer();
      if (shouldLogout) {
        await axios.post(`${BaseUrl}/api/logout`, {
          email: userEmail,
        });

        localStorage.clear();
        setCurrToken("RESET_INIT_AUTH");
        clearPayProcessState();
        clearCurriculumState();
        history.push("/");
      }
    };
    logoutWhenNeeded();
    // return () => {};
  }, [
    currToken,
    userEmail,
    shouldLogout,
    clearCurriculumState,
    clearPayProcessState,
    history,
    resetModalReducer,
    setCurrToken,
  ]);

  if (!navIntlText || Object.keys(navIntlText).length === 0) {
    return null; // in shared compo, no need spinner
  }
  return (
    <>
      {SetLoginSingupOrLogout(navIntlText, currUserToken || currToken, "").map(
        (el, idx) =>
          el.key === "Menu-Logout" ? (
            <Button
              key={el.key}
              color="primary"
              size="small"
              onClick={() => {
                openCloseLogoutModal(true);
              }}
            >
              {el.word}
            </Button>
          ) : (
            <Button
              key={el.key}
              color="primary"
              size="small"
              onClick={() => {
                openCloseSignupLoginModal({
                  shouldOpenSignupLoginModal: true,
                  whatModalIsThisUserAction: el.key.includes("-Signup")
                    ? UserRegisterModal.signup
                    : UserRegisterModal.login,
                });
              }}
            >
              {el.word}
            </Button>
          )
      )}
    </>
  );
};

RegisterBtn.propTypes = {
  currToken: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  setCurrToken: PropTypes.func.isRequired,
  navIntlText: PropTypes.object.isRequired,
  shouldLogout: PropTypes.bool.isRequired,
  openCloseLogoutModal: PropTypes.func.isRequired,
  resetModalReducer: PropTypes.func.isRequired,
  clearPayProcessState: PropTypes.func.isRequired,
  clearCurriculumState: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  Authen: { currToken, userEmail },
  ModalRdx: { shouldLogout },
}) => ({
  currToken,
  userEmail,
  shouldLogout,
});

const mapDispatchToProps = (dispatch) => ({
  openCloseSignupLoginModal: (isOpenObj) =>
    dispatch(openCloseSignupLoginModal(isOpenObj)),
  setCurrToken: (token) => dispatch(setCurrToken(token)),
  openCloseLogoutModal: (token) => dispatch(openCloseLogoutModal(token)),
  resetModalReducer: () => dispatch(resetModalReducer()),
  clearPayProcessState: () => dispatch(clearPayProcessState()),
  clearCurriculumState: () => dispatch(clearCurriculumState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterBtn));
