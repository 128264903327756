import {
    GRAB_INITIAL_BROWSER_LANGUAGE/* ,
    SWITCH_RECTO_VOCABULARY */
} from '../types';

// payload ~ { langID, chunk }
export const SetBrowserLanguage = (payload) => ({
    type: GRAB_INITIAL_BROWSER_LANGUAGE,
    payload
});

/* export const SwitchVocabulary = (payload) => ({
    type: SWITCH_RECTO_VOCABULARY,
    payload
}); */
