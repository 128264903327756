/* eslint react-hooks/exhaustive-deps: 0 */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
// import "moment/locale/zh-cn";
import { SingleDatePicker } from "react-dates";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";

import { apiRoutes } from "../constant/BaseUrl";
import { storageStr } from "../constant/LocalStoreConstant";
import { TimeFormat } from "../constant/TimeFormat";
import IsMomentBeforeDay from "../helpers/IsMomentBeforeDay";
import PaymentResponse from "../helpers/responses/PaymentResponse";
import CoursesResponse from "../helpers/responses/CoursesResponse";
import { setRecentTwoMonthsCourses } from "../reducers/Courses.Action";
import {
  receiveRegisterCourse,
  clearPayProcessState,
} from "../reducers/Payment.Action";

const initCourseData = {
  userEmail: "",
  fbID: "",
  courseName: "",
  registerDate: null,
  registerActionTime: "",
  isCheckedPaymentAgreement: false,
  isPaid: false,
};

const WhiteCardApply = ({
  wcLang,
  btnLang,
  currToken,
  userEmail,
  userRole,
  firstname,
  lastname,
  // twoMonthsCourses,
  coursesError,
  paymentError,
  receiveRegisterCourse,
  setRecentTwoMonthsCourses,
  clearPayProcessState,
}) => {
  const [payCourseForm, setPayCourseForm] = useState(initCourseData);
  const [isCalendarFocused, setIsCalendarFocused] = useState(false);
  const [
    allCourseDataTheseTwoMonthes,
    setAllCourseDataTheseTwoMonthes,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    paymentError !== "" && clearPayProcessState();
    const obtainTwoMonthsCourse = async () => {
      userRole === "student" && (await fetchTwoMonthsCourse());
    };
    coursesError !== "Network Error"
      ? obtainTwoMonthsCourse()
      : localStorage.clear();
    // moment.locale("zh-cn");
    if (currToken && currToken.length > 0) {
      setPayCourseForm({
        ...payCourseForm,
        userEmail,
      });
    }
    // will have update about getting new registered course
    // return () => { cleanup }
  }, [
    currToken,
    userEmail,
    userRole,
    paymentError,
    coursesError,
    clearPayProcessState,
  ]);

  const fetchTwoMonthsCourse = async () => {
    const twoMonthsCourses = await CoursesResponse({
      token: currToken || localStorage.getItem(storageStr.utk),
      route: apiRoutes.twoMonths,
    });
    await setRecentTwoMonthsCourses(twoMonthsCourses);
    if (
      twoMonthsCourses &&
      Object.keys(twoMonthsCourses).length > 0 &&
      twoMonthsCourses.success
    ) {
      setAllCourseDataTheseTwoMonthes(
        twoMonthsCourses.data.filter(
          (el) =>
            moment(el.registerDate).isAfter(moment()) &&
            moment(el.registerDate).isSameOrBefore(
              moment().add(1, "M").endOf("month")
            ) &&
            moment(el.registerDate).get("day") === 0 &&
            parseInt(el.registerDate.split("-")[0]) === moment().get("year") &&
            moment(el.registerDate).get("date") >= 1 &&
            moment(el.registerDate).get("date") <= 31
        )
      );
    }
  };

  // count dates to avoid more than 20 users logged in
  const sameDateCount = (courseWithValidDates, calendarDate) => {
    let count = 0;
    courseWithValidDates.forEach((el) => {
      if (
        moment(
          moment(calendarDate).startOf("day").format(TimeFormat.normalDate)
        ).isSame(el.registerDate)
      ) {
        count++;
      }
    });
    return count;
  };

  const displayDayContent = (day, theseTwoMonthesValidDates) => (
    <div>
      {moment(day).get("date")}
      {moment(day).weekday() === 0 &&
        moment(day).isSameOrAfter(moment()) &&
        moment(day).isSameOrBefore(moment().add(1, "M").endOf("month")) &&
        theseTwoMonthesValidDates.length > 0 && (
          <Badge
            className="general-badge"
            badgeContent={sameDateCount(theseTwoMonthesValidDates, day)}
            color={
              sameDateCount(theseTwoMonthesValidDates, day) < 20
                ? "primary"
                : "secondary"
            }
          ></Badge>
        )}
    </div>
  );

  const validateForm = () => {
    const {
      userEmail,
      fbID,
      courseName,
      registerDate,
      /* registerActionTime,
      isCheckedPaymentAgreement,
      isPaid, */
    } = payCourseForm;
    return (
      ((!userEmail || userEmail === "") && (!fbID || fbID === "")) ||
      !courseName ||
      courseName === "" ||
      !registerDate ||
      registerDate === "" ||
      userRole === "Superintendent"
    );
  };

  const registerCourse = async () => {
    setIsLoading(true);
    const payRz = await PaymentResponse({
      token: currToken || localStorage.getItem(storageStr.utk),
      route: apiRoutes.payRegisteredCourse,
      data: { ...payCourseForm, firstname, lastname },
    });
    if (payRz && Object.keys(payRz).length > 0) {
      setIsLoading(false);
      payRz.success && setPayCourseForm(initCourseData);
      await receiveRegisterCourse(payRz);
      await fetchTwoMonthsCourse();
    }
  };

  return (
    <>
      <SingleDatePicker
        id="whitecard-apply_Hdaypicker" // PropTypes.string.isRequired,
        date={payCourseForm.registerDate} // momentPropTypes.momentObj or null
        onDateChange={(date) => {
          setPayCourseForm({
            ...payCourseForm,
            registerDate: moment(date, TimeFormat.normalDate),
          });
        }} // PropTypes.func.isRequired, eg.  this.setState({ date })
        focused={isCalendarFocused} // PropTypes.bool
        onFocusChange={({ focused }) => {
          setIsCalendarFocused(focused);
        }} // PropTypes.func.isRequired   eg. this.setState({ focused })
        placeholder={wcLang.placeholder}
        ariaLabel={wcLang.ariaLabel}
        disabled={
          userRole === "Superintendent" || !currToken || currToken === ""
        }
        readOnly={true}
        screenReaderInputMessage={wcLang.screenReaderMsg}
        // showClearDate={true}
        // showDefaultInputIcon={true}
        // noBorder={true}
        // block={true}
        orientation={
          window.matchMedia("(max-width: 836px)").matches
            ? "vertical"
            : "horizontal"
        }
        // withPortal={true}
        anchorDirection={"left"}
        openDirection={"down"}
        // horizontalMargin={3}
        appendToBody={true}
        // initialVisibleMonth={() => moment().add(2, "M")}
        numberOfMonths={2}
        renderCalendarInfo={() => (
          <div>
            {/* <p>{false && <AlertInfor {...toggleAlert} setShowFunc={setToggleAlert} />}</p> */}
            <p>{wcLang.calenderInfo}</p>
          </div>
        )}
        hideKeyboardShortcutsPanel={true}
        daySize={54}
        isRTL={false}
        renderDayContents={(day) =>
          displayDayContent(day, allCourseDataTheseTwoMonthes)
        }
        navPrev={<div hidden={true}>Prev</div>}
        navNext={<div hidden={true}>Next</div>}
        isDayBlocked={(day) => moment(day).weekday() !== 0}
        isOutsideRange={(day) =>
          !moment.isMoment(day)
            ? false
            : IsMomentBeforeDay(day, moment()) ||
              moment(day).isAfter(moment().add(1, "M").endOf("month"))
        }
        isDayHighlighted={(day) => {}}
      />
      <FormControl className="general-formCtrl">
        <InputLabel htmlFor="grouped-select">{wcLang.courses}</InputLabel>
        {/* defaultValue={wcLang.dropdownOpts[0].value} */}
        <Select
          name="courseName"
          value={payCourseForm.courseName}
          disabled={
            userRole === "Superintendent" || !currToken || currToken === ""
          }
          onChange={(event) => {
            const { value, name } = event.target;
            // console.log(
            //   "241 -- moment.unix() * 1000: ",
            //   moment().unix() * 1000
            // );
            !value
              ? setPayCourseForm({
                  ...payCourseForm,
                  [name]: "",
                  registerActionTime: "",
                })
              : setPayCourseForm({
                  ...payCourseForm,
                  [name]: value,
                  registerActionTime: String(moment().unix() * 1000),
                });
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {wcLang.dropdownOpts.map((el, idx) => {
            return (
              <MenuItem key={el + idx} value={el.value}>
                {el.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        // type="submit"
        size="large"
        className="text-capitalize"
        disabled={validateForm()}
        onClick={registerCourse}
      >
        {btnLang.Submit}
        {isLoading && <CircularProgress size="1rem" color="inherit" />}
      </Button>
    </>
  );
};

WhiteCardApply.propTypes = {
  currToken: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  twoMonthsCourses: PropTypes.array.isRequired,
  coursesError: PropTypes.string.isRequired,
  paymentError: PropTypes.string,
  setRecentTwoMonthsCourses: PropTypes.func.isRequired,
  receiveRegisterCourse: PropTypes.func.isRequired,
  clearPayProcessState: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  Authen: { currToken, userEmail, userRole, firstname, lastname },
  Courses: { twoMonthsCourses, coursesError },
  Payment: { paymentError },
}) => ({
  currToken,
  userEmail,
  userRole,
  firstname,
  lastname,
  twoMonthsCourses,
  coursesError,
  paymentError,
});
const mapDispatchToProps = (dispatch) => ({
  receiveRegisterCourse: (obj) => dispatch(receiveRegisterCourse(obj)),
  setRecentTwoMonthsCourses: (obj) => dispatch(setRecentTwoMonthsCourses(obj)),
  clearPayProcessState: () => dispatch(clearPayProcessState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhiteCardApply);
