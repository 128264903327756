import {
  RECEIVE_PAYMENT_RESULT,
  CLEAR_PAYMENT_STATE,
} from "../types";

// payload ~ { langID, chunk }
export const receiveRegisterCourse = (payload) => ({
  type: RECEIVE_PAYMENT_RESULT,
  payload,
});

export const clearPayProcessState = () => ({
  type: CLEAR_PAYMENT_STATE,
});
