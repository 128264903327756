// export const BaseUrl = "http://localhost:8080";
// export const BaseUrl = "https://hiwhitecardbackend.com";
export const BaseUrl = "https://henrytrainingschool.com";

export const apiRoutes = {
  userCourses: "/userCourses",
  twoMonths: "/twoMonthesCourses",
  thisWeek: "/thisWeekCourses",
  payRegisteredCourse: "/acquisitionAndCreateUserCourse",
};
