import { RouteLinksKeysText } from "../constant/RouteRelated";
import { GetIntlNavObjKey } from "./GetIntlNavObjKey";

export default (intlNavText, token, role) => {
  if (token && token.length > 0) {
    return RouteLinksKeysText.filter((el) =>
      role === "student" && el.key !== "Menu-AdminMag"
        ? true
        : role === "Superintendent" && el.key !== "Menu-UserMag"
        ? true
        : false
    ).map((el) => {
      if (!el.hasOwnProperty("otherCourses")) {
        return {
          ...el,
          word: intlNavText[GetIntlNavObjKey(intlNavText, el)],
        };
        /* if (
						el.key === "Menu-UserMag" ||
						el.key === "Menu-AdminMag"
					) */
      } else {
        return {
          otherCourses: el.otherCourses.map((ocEl) => ({
            ...ocEl,
            word: intlNavText[GetIntlNavObjKey(intlNavText, ocEl)],
          })),
        };
      }
    });
  } else {
    return RouteLinksKeysText.filter(
      (el) =>
        el.key !== "Menu-UserMag" &&
        el.key !== "Menu-AdminMag" &&
        el.key !== "Menu-FbUserMag"
    ).map((el) => {
      return el.hasOwnProperty("otherCourses")
        ? {
            otherCourses: el.otherCourses.map((ocEl) => {
              return {
                ...ocEl,
                word: intlNavText[GetIntlNavObjKey(intlNavText, ocEl)],
              };
            }),
          }
        : {
            ...el,
            word: intlNavText[GetIntlNavObjKey(intlNavText, el)],
          };
    });
  }
};
