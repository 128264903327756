import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import htsLogo from '../_imgs/htsLogo_80.png';
import { LanguageOpts } from "../constant/LanguageOpts";
import { RouteLinks } from "../constant/RouteRelated";
import { SetBrowserLanguage } from "../reducers/Lang.Action";
import { openCloseSignupLoginModal } from "../reducers/Modal.Action";
import PrimaryMenu from "./PrimaryMenu";
import SideDrawer from "./SideDrawer";
import LoginSignupModal from "../containers/LoginSignupModal";
import ConfirmModal from "../containers/ConfirmModal";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fff",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  logoAround: {
    textDecoration: "none",
    marginRight: theme.spacing(1),
  },
  drawer: {
    // width: drawerWidth, // to avoid extra white space on right side
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

const Navigation = ({
  SetBrowserLanguage,
  openCloseSignupLoginModal,
  Lang,
}) => {
  const classes = useStyles();
  const theTheme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    !localStorage.getItem("langID")
      ? SetBrowserLanguage({
          langID: LanguageOpts.enCode,
        })
      : SetBrowserLanguage({
          langID: localStorage.getItem("langID"),
        });
    // return () => {}
  }, [SetBrowserLanguage]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={clsx(classes.appBar)}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <NavLink
              to={RouteLinks.initLand}
              exact
              className={classes.logoAround}
            >
              <img className="logo-50px" src={htsLogo} alt="hts_logo" />
            </NavLink>
          </Typography>
          <PrimaryMenu
            Lang={Lang}
            currLang={Lang.chosenLang}
            navIntlText={Lang.navigation_name}
            openCloseSignupLoginModal={openCloseSignupLoginModal}
          />
          <div className={classes.grow} />
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(
              "mobilehamburger-disappear",
              drawerOpen && classes.hide
            )}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <SideDrawer
        classes={{
          drawer: classes.drawer,
          drawerPaper: classes.drawerPaper,
          drawerHeader: classes.drawerHeader,
        }}
        currLang={Lang.chosenLang}
        theme={theTheme}
        open={drawerOpen}
        navIntlText={Lang.navigation_name}
        handleDrawerClose={handleDrawerClose}
      />
      <LoginSignupModal />
      <ConfirmModal />
    </div>
  );
};

Navigation.propTypes = {
  Lang: PropTypes.object.isRequired,
  SetBrowserLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Lang, FbUsers: { fbLoggedIn } }) => ({
  Lang,
  fbLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  SetBrowserLanguage: (langPiece) => dispatch(SetBrowserLanguage(langPiece)),
  openCloseSignupLoginModal: (isOpenObj) =>
    dispatch(openCloseSignupLoginModal(isOpenObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
