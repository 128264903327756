export const RouteLinks = {
  initLand: "/",
  // rpl: "/rpl",
  gallery: "/gallery",
  intro: "/intro",
  approach: "/approach",
  management: "/management",
  fbUserManagement: "/fbUserManagement",
  tile: "/tile",
  waterproof: "/waterproof",
};

// User Management
/* "InitPage": "White Card",
"Gallery": "Gallery",
"Intro": "Intro",
"Approach": "Contact Us",
"UserMag": "User Management",
"AdminMag": "Administrator Management",
"Tile": "Tile",
"Waterproof": "Waterproof",
"Login": "Log In",
"Signup": "Sign Up",
"Logout": "Log Out",
"DrawerTitle": "Side Menu" */

export const RouteLinksKeysText = [
  {
    to: RouteLinks.initLand,
    key: "Menu-InitPage",
    word: "White Card",
  },
  /* {
    to: RouteLinks.rpl,
    key: "Menu-Rpl",
    word: "Rpl",
  }, */
  {
    to: RouteLinks.gallery,
    key: "Menu-Gallery",
    word: "Gallery",
  },
  {
    to: RouteLinks.intro,
    key: "Menu-Intro",
    word: "Introduction",
  },
  {
    to: RouteLinks.approach,
    key: "Menu-Approach",
    word: "Contact Us",
  },
  {
    to: RouteLinks.management,
    key: "Menu-UserMag",
    word: "User Management",
  },
  {
    to: RouteLinks.management,
    key: "Menu-AdminMag",
    word: "Admin Management",
  },
  {
    to: RouteLinks.fbUserManagement,
    key: "Menu-FbUserMag",
    word: "Fb User Management",
  },
  {
    otherCourses: [
      {
        to: RouteLinks.tile,
        key: "Menu-Subgroup-Tile",
        word: "Tile",
      },
      {
        to: RouteLinks.waterproof,
        key: "Menu-Subgroup-Waterproof",
        word: "Waterproof",
      },
    ],
  },
];

export const LoginoutSignup = [
  {
    key: "Menu-Login",
    // to: '/signin',
    word: "Log In",
  },
  {
    key: "Menu-Signup",
    word: "Sign Up",
  },
  {
    key: "Menu-Logout",
    word: "Log Out",
  },
];
