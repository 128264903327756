import { combineReducers } from "redux";

import Authen from "./Auth.Reducer";
import Lang from "./Lang.Reducer";
import ModalRdx from "./Modal.Reducer";
import FbUsers from "./FbUser.Reducer";
import Payment from "./Payment.Reducer";
import Courses from "./Courses.Reducer";

// localize is key word for react-localize-redux, so can't be changed.
export default combineReducers({
  Authen,
  Lang,
  ModalRdx,
  FbUsers,
  Payment,
  Courses,
});
