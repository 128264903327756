export const ReactIntlAutoPickLocalLanguage = () =>
  navigator.language.split("-")[0]
    ? navigator.language.split("-")[0] === "zh" ||
      navigator.language.split("-")[0] === "en"
      ? navigator.language.split("-")[0]
      : "en"
    : "en";

/* const possibleLocale = navigator.language.split('-')[0] || 'en';
addLocaleData(require(`react-intl/locale-data/${possibleLocale}`)); */
