import { RECORD_FB_USER_SIGNIN, CLEAR_LOGGEDIN_FBUSER } from "../types";

export const recordFbUserLogin = (payload) => ({
	type: RECORD_FB_USER_SIGNIN,
	payload,
});

export const clearFbsigninUser = () => ({
	type: CLEAR_LOGGEDIN_FBUSER,
});
