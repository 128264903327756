import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  openCloseLogoutModal,
  userConfirmOrDisagreeLogout,
} from "../reducers/Modal.Action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({
  button_Lang,
  Confirm_modal_Lang,
  shouldOpenLogoutModal,
  openCloseLogoutModal,
  userConfirmOrDisagreeLogout,
}) => {
  if (
    !Confirm_modal_Lang ||
    Object.keys(Confirm_modal_Lang).length === 0 ||
    !button_Lang ||
    Object.keys(button_Lang).length === 0
  ) {
    return null; // no need spinner...
  }
  return (
    <Dialog
      open={shouldOpenLogoutModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        openCloseLogoutModal(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {Confirm_modal_Lang.confirmLogoutModalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {Confirm_modal_Lang.confirmLogoutText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            userConfirmOrDisagreeLogout(false);
          }}
          color="primary"
        >
          {button_Lang.Cancel}
        </Button>
        <Button
          onClick={() => {
            userConfirmOrDisagreeLogout(true);
          }}
          color="primary"
        >
          {button_Lang.Confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  button_Lang: PropTypes.object,
  Confirm_modal_Lang: PropTypes.object,
  shouldOpenLogoutModal: PropTypes.bool.isRequired,
  openCloseLogoutModal: PropTypes.func.isRequired,
  userConfirmOrDisagreeLogout: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  Lang: { button_Lang, Confirm_modal_Lang },
  ModalRdx: { shouldOpenLogoutModal },
}) => ({
  button_Lang,
  Confirm_modal_Lang,
  shouldOpenLogoutModal,
});

const mapDispatchToProps = (dispatch) => ({
  openCloseLogoutModal: (isOpenModal) =>
    dispatch(openCloseLogoutModal(isOpenModal)),
  userConfirmOrDisagreeLogout: (isLogout) =>
    dispatch(userConfirmOrDisagreeLogout(isLogout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
