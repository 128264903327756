// language
export const GRAB_INITIAL_BROWSER_LANGUAGE =
  "hWhiteCard/lang-GRAB_INITIAL_BROWSER_LANGUAGE";
// export const SWITCH_RECTO_VOCABULARY = 'hWhiteCard/lang-SWITCH_RECTO_VOCABULARY';

// modal
export const OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL =
  "hWhiteCard/lang-OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL";
export const OPEN_OR_CLOSE_LOGOUT_MODAL =
  "hWhiteCard/lang-OPEN_OR_CLOSE_LOGOUT_MODAL";
export const CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL =
  "hWhiteCard/lang-CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL";
export const RESET_MODAL_REDUCER_DATA =
  "hWhiteCard/lang-RESET_MODAL_REDUCER_DATA";

// fb user actions
export const RECORD_FB_USER_SIGNIN = "hWhiteCard/fbuser-RECORD_FB_USER_SIGNIN";
export const CLEAR_LOGGEDIN_FBUSER = "hWhiteCard/fbuser-CLEAR_LOGGEDIN_FBUSER";

// auth actions
export const SET_CURRENT_TOKEN = "hWhiteCard/authentication-SET_CURRENT_TOKEN";

// payment actions
export const RECEIVE_PAYMENT_RESULT =
  "hWhiteCard/payment-RECEIVE_PAYMENT_RESULT";
export const CLEAR_PAYMENT_STATE = "hWhiteCard/payment-CLEAR_PAYMENT_STATE";

// courses actions
export const SET_RECENT_TWO_MONTHS_CURRICULUM =
  "hWhiteCard/curriculum-SET_RECENT_TWO_MONTHS_CURRICULUM";
export const SET_RECENT_THISWEEK_CURRICULUM =
  "hWhiteCard/curriculum-SET_RECENT_THISWEEK_CURRICULUM";
export const SET_USER_REGISTERED_CURRICULUM =
  "hWhiteCard/curriculum-SET_USER_REGISTERED_CURRICULUM";
export const RESET_COURSES_ERROR = "hWhiteCard/curriculum-RESET_COURSES_ERROR";
export const CLEAR_CURRICULUM_STATE =
  "hWhiteCard/curriculum-CLEAR_CURRICULUM_STATE";
