import {
  OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL,
  OPEN_OR_CLOSE_LOGOUT_MODAL,
  CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL,
  RESET_MODAL_REDUCER_DATA,
} from "../types";

const modalInitState = {
  shouldOpenSignupLoginModal: false,
  whatModalIsThisUserAction: "",
  shouldOpenLogoutModal: false,
  shouldLogout: false,
};

export default (state = modalInitState, { type, payload }) => {
  switch (type) {
    case OPEN_OR_CLOSE_SIGNUP_LOGIN_MODAL:
      const { shouldOpenSignupLoginModal, whatModalIsThisUserAction } = payload;
      return {
        ...state,
        shouldOpenSignupLoginModal,
        whatModalIsThisUserAction,
      };
    case OPEN_OR_CLOSE_LOGOUT_MODAL:
      return {
        ...state,
        shouldOpenLogoutModal: payload,
      };
    case CONFIRM_OR_CANCEL_LOGOUT_FROM_MODAL:
      return payload
        ? {
            ...state,
            shouldOpenLogoutModal: !payload,
            shouldLogout: payload,
          }
        : {
            ...state,
            shouldOpenLogoutModal: payload,
            shouldLogout: payload,
          };
    case RESET_MODAL_REDUCER_DATA:
      return {
        shouldOpenSignupLoginModal: false,
        whatModalIsThisUserAction: "",
        shouldOpenLogoutModal: false,
        shouldLogout: false,
      };
    default:
      return state;
  }
};
