/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import { RouteLinksKeysText } from "../constant/RouteRelated";
import { storageStr } from "../constant/LocalStoreConstant";
import { GetIntlNavObjKey } from "../helpers/GetIntlNavObjKey";
import storeToken from "../helpers/LocalStorageSet";
// import OtherCoursesDropdown from "./OtherCoursesDropdown";
import ZhEnBtn from "./ZhEnBtn";
import RegisterBtn from "./RegisterBtn";
import { setCurrToken } from "../reducers/Auth.Action";

const PrimaryMenu = ({
  Lang,
  currLang,
  navIntlText,
  currToken,
  userRole,
  setCurrToken,
}) => {
  useEffect(() => {
    const tokenInLocalStorage = localStorage.getItem(storageStr.utk);
    if (
      currToken === "" &&
      tokenInLocalStorage &&
      tokenInLocalStorage.length > 0
    ) {
      setCurrToken(tokenInLocalStorage);
    }
    if (
      currToken &&
      currToken.length > 0 &&
      (!tokenInLocalStorage || tokenInLocalStorage === "")
    ) {
      storeToken(storageStr.utk, currToken);
    }
    if (currToken === "" && !tokenInLocalStorage) {
      setCurrToken("RESET_INIT_AUTH");
    }
    // return () => {};
  }, [currToken]);

  const mergeIntlLangText = (intlNavText) =>
    RouteLinksKeysText.map((el) =>
      el.hasOwnProperty("otherCourses")
        ? {
            otherCourses: el.otherCourses.map((ocEl) => ({
              ...ocEl,
              word: intlNavText[GetIntlNavObjKey(intlNavText, ocEl)],
            })),
          }
        : {
            ...el,
            word: intlNavText[GetIntlNavObjKey(intlNavText, el)],
          }
    );

  const renderLeftNavbar = (rkt, fbLoggedIn) =>
    rkt.map((el, idx) => {
      if (!el.hasOwnProperty("otherCourses")) {
        if (el.key === "Menu-InitPage") {
          return (
            <Button
              key={el.key}
              color="primary"
              // variant="contained"
              size="small"
            >
              <NavLink className="appnav-link" to={el.to} exact>
                {el.word}
              </NavLink>
            </Button>
          );
        } else if (
          el.key === "Menu-UserMag" ||
          el.key === "Menu-AdminMag" ||
          el.key === "Menu-FbUserMag"
        ) {
          if (currToken && currToken.length > 0) {
            switch (el.key) {
              case "Menu-UserMag":
                return (
                  userRole === "student" && (
                    <Button
                      key={el.key}
                      color="primary"
                      // variant="contained"
                      size="small"
                    >
                      <NavLink className="appnav-link" to={el.to}>
                        {el.word}
                      </NavLink>
                    </Button>
                  )
                );
              case "Menu-AdminMag":
                return (
                  userRole === "Superintendent" && (
                    <Button
                      key={el.key}
                      color="primary"
                      // variant="contained"
                      size="small"
                    >
                      <NavLink className="appnav-link" to={el.to}>
                        {el.word}
                      </NavLink>
                    </Button>
                  )
                );
              default:
                return null;
            }
          } else if (
            !!fbLoggedIn &&
            fbLoggedIn.length > 0 &&
            el.key === "Menu-FbUserMag"
          ) {
            return null;
            /* return (
              <Button
                key={el.key}
                color="primary"
                variant="contained"
                size="small"
              >
                <NavLink className="appnav-link" to={el.to}>
                  {el.word}
                </NavLink>
              </Button>
            ); */
          } else return null;
        } else {
          return (
            <Button
              key={el.key}
              color="primary"
              // variant="contained"
              size="small"
            >
              <NavLink className="appnav-link" to={el.to}>
                {el.word}
              </NavLink>
            </Button>
          );
        }
      } else {
        return null;
        /* return (
          <OtherCoursesDropdown
            key={`${idx}_MenuFor_SubCourses`}
            otherCourses={el.otherCourses}
          />
        ); */
      }
    });

  return (
    <div className="appnavbar-disappear">
      {/* <IconButton aria-label="This is one." color="inherit">
        <Badge badgeContent={4} color="secondary">
          <MailIcon />
        </Badge>
      </IconButton> */}
      {renderLeftNavbar(mergeIntlLangText(navIntlText))}
      <ZhEnBtn currLang={currLang} />
      <RegisterBtn navIntlText={Lang.navigation_name} />
    </div>
  );
};

PrimaryMenu.propTypes = {
  Lang: PropTypes.object.isRequired,
  currLang: PropTypes.string.isRequired,
  navIntlText: PropTypes.object.isRequired,
  currToken: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  setCurrToken: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Authen: { currToken, userRole } }) => ({
  currToken,
  userRole,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrToken: (token) => dispatch(setCurrToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryMenu);
