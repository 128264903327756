import {
  SET_RECENT_TWO_MONTHS_CURRICULUM,
  SET_RECENT_THISWEEK_CURRICULUM,
  SET_USER_REGISTERED_CURRICULUM,
  RESET_COURSES_ERROR,
  CLEAR_CURRICULUM_STATE,
} from "../types";

const initialCoursesState = {
  twoMonthsCourses: [],
  thisWeekCourses: [],
  userRegisteredCourses: [],
  coursesError: "",
};

export default (state = initialCoursesState, { type, payload }) => {
  switch (type) {
    case SET_RECENT_TWO_MONTHS_CURRICULUM:
      if (!payload || !payload.success) {
        return {
          ...state,
          twoMonthsCourses: [],
          coursesError: !payload
            ? ""
            : !payload.coursesError
            ? payload.message
              ? payload.message
              : ""
            : payload.coursesError,
        };
      } else {
        return {
          ...state,
          twoMonthsCourses: payload.data,
          coursesError: "",
        };
      }
    case SET_RECENT_THISWEEK_CURRICULUM: {
      if (!payload || !payload.success) {
        return {
          ...state,
          thisWeekCourses: [],
          coursesError: !payload
            ? ""
            : !payload.coursesError
            ? payload.message
              ? payload.message
              : ""
            : payload.coursesError,
        };
      } else {
        return {
          ...state,
          thisWeekCourses: payload.data,
          coursesError: "",
        };
      }
    }
    case SET_USER_REGISTERED_CURRICULUM:
      if (!payload || !payload.success) {
        return {
          ...state,
          userRegisteredCourses: [],
          coursesError: !payload
            ? ""
            : !payload.coursesError
            ? payload.message
              ? payload.message
              : ""
            : payload.coursesError,
        };
      } else {
        return {
          ...state,
          userRegisteredCourses: payload.data,
          coursesError: "",
        };
      }
    case RESET_COURSES_ERROR:
      return {
        ...state,
        coursesError: "",
      };
    case CLEAR_CURRICULUM_STATE:
      return {
        twoMonthsCourses: [],
        thisWeekCourses: [],
        userRegisteredCourses: [],
        coursesError: "",
      };
    default:
      return state;
  }
};
