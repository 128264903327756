import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Container, Grid, Typography } from "@material-ui/core";

import Spinner from "../shared/Spinner";
import WhiteCardApply from "../containers/WhiteCardApply";

const useStyles = makeStyles(() => ({
  alignSelfCenter: {
    alignSelf: "center",
  },
}));

const LandingWhiteCard = ({ white_card, button_Lang }) => {
  const classes = useStyles();
  if (!white_card || Object.keys(white_card).length === 0 || !button_Lang) {
    return <Spinner />;
  }
  return (
    <Container>
      <Grid container className="my-1rem">
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography variant="h4">{white_card.registerCourse}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
          xl={4}
          className={classes.alignSelfCenter}
        >
          <Typography variant="body2">
            {white_card.loginThenRegisterCourse}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {/* <Grid item>white card apply form...</Grid> */}
        <WhiteCardApply wcLang={white_card} btnLang={button_Lang} />
      </Grid>
    </Container>
  );
};

LandingWhiteCard.propTypes = {
  white_card: PropTypes.object,
  button_Lang: PropTypes.object,
};

const mapStateToProps = ({ Lang: { white_card, button_Lang } }) => ({
  white_card,
  button_Lang,
});
/* const mapDispatchToProps = (dispatch) => ({
}); */

export default connect(mapStateToProps, null)(LandingWhiteCard);
