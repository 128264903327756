import {
  SET_RECENT_TWO_MONTHS_CURRICULUM,
  SET_RECENT_THISWEEK_CURRICULUM,
  SET_USER_REGISTERED_CURRICULUM,
  RESET_COURSES_ERROR,
  CLEAR_CURRICULUM_STATE,
} from "../types";

// payload ~ { langID, chunk }
export const setRecentTwoMonthsCourses = (payload) => ({
  type: SET_RECENT_TWO_MONTHS_CURRICULUM,
  payload,
});

export const setThisWeekCourses = (payload) => ({
  type: SET_RECENT_THISWEEK_CURRICULUM,
  payload,
});

export const setUserRegisteredCourses = (payload) => ({
  type: SET_USER_REGISTERED_CURRICULUM,
  payload,
});

export const clearCoursesError = () => ({
  type: RESET_COURSES_ERROR,
});

export const clearCurriculumState = () => ({
  type: CLEAR_CURRICULUM_STATE,
});
