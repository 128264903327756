import React from "react";
import ReactDOM from "react-dom";
import "react-dates/initialize";
import { Provider } from "react-redux";

import App from "./App";
import { configStore } from "./store/configStore";
import "./scss/index.scss";
import reportWebVitals from "./reportWebVitals";

const store = configStore();

const utensil = document.getElementById("WhiteCardSchool-App");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  utensil
);

// <React.StrictMode>
// </React.StrictMode>,

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
