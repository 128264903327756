// /* global FB */
import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Divider from "@material-ui/core/Divider";

import { openCloseSignupLoginModal } from "../reducers/Modal.Action";
import { recordFbUserLogin } from "../reducers/FbUser.Action";
import { UserRegisterModal } from "../constant/UserRegisterModal";
import AlertInfor from "../shared/AlertInfor";
import Spinner from "../shared/Spinner";
import UserRegisterForm from "./UserRegisterForm";

// const initLoadings = []; // the each val for this array is boolean, like postion0 has true, then fb user login and loading, position2 becomes true, then other user logging and loading.

const initUserFormVals = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "61",
  pno: "",
  password: "",
  role: "student",
  // role: "Superintendent",
  status: "login",
};

/* const initUserFormVals = {
  firstName: "jack",
  lastName: "jack",
  email: "jack@gmail.com",
  countryCode: "61",
  pno: "406689837",
  password: "s2@sWdqilada",
  role: "student",
  // role: "Superintendent",
  status: "login",
}; */

const initAlert = {
  isShow: false,
  grimness: "error",
  msg: "",
};

const LoginSignupModal = ({
  Login_signup_modal_Lang,
  button_Lang,
  shouldOpenSignupLoginModal,
  whatModalIsThisUserAction,
  openCloseSignupLoginModal,
  recordFbUserLogin,
}) => {
  const [userFormVals, setUserFormVals] = useState(initUserFormVals);
  // const [theLoadings, setTheLoadings] = useState(initLoadings); // the each val for this array is boolean, like postion0 has true, then fb user login and loading, position2 becomes true, then other user logging and loading.
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [toggleAlert, setToggleAlert] = useState(initAlert);
  const [otpCode, setOtpCode] = useState("");

  const handleCancel = () => {
    openCloseSignupLoginModal({
      shouldOpenSignupLoginModal: false,
      whatModalIsThisUserAction: "",
    });
    setUserFormVals(initUserFormVals);
    setIsSubmitted(false);
    setOtpShow(false);
    setOtpCode("");
    setToggleAlert(initAlert);
  };

  if (
    !Login_signup_modal_Lang ||
    Object.keys(Login_signup_modal_Lang).length === 0 ||
    !button_Lang ||
    Object.keys(button_Lang).length === 0
  ) {
    return <Spinner />;
  }
  return (
    <>
      <AlertInfor {...toggleAlert} setShowFunc={setToggleAlert} />
      <Dialog
        open={shouldOpenSignupLoginModal}
        onClose={handleCancel}
        aria-labelledby="login-signup-modal"
      >
        <DialogTitle id="login-signup-modal">
          {whatModalIsThisUserAction === UserRegisterModal.login
            ? Login_signup_modal_Lang.login
            : Login_signup_modal_Lang.signup}
        </DialogTitle>
        <Divider />
        <UserRegisterForm
          whatModalIsThisUserAction={whatModalIsThisUserAction}
          states={{
            userFormVals,
            isSubmitted,
            otpShow,
            otpCode,
          }}
          stateFuncs={{
            setUserFormVals,
            setIsSubmitted,
            setOtpShow,
            setToggleAlert,
            setOtpCode,
          }}
          handleCancel={handleCancel}
        />
      </Dialog>
    </>
  );
};

LoginSignupModal.propTypes = {
  Login_signup_modal_Lang: PropTypes.object,
  button_Lang: PropTypes.object,
  shouldOpenSignupLoginModal: PropTypes.bool.isRequired,
  whatModalIsThisUserAction: PropTypes.string.isRequired,
  openCloseSignupLoginModal: PropTypes.func.isRequired,
  recordFbUserLogin: PropTypes.func,
};

const mapStateToProps = ({
  Lang: { Login_signup_modal_Lang, button_Lang },
  ModalRdx: { shouldOpenSignupLoginModal, whatModalIsThisUserAction },
}) => ({
  Login_signup_modal_Lang,
  button_Lang,
  shouldOpenSignupLoginModal,
  whatModalIsThisUserAction,
});

const mapDispatchToProps = (dispatch) => ({
  openCloseSignupLoginModal: (isOpenObj) =>
    dispatch(openCloseSignupLoginModal(isOpenObj)),
  recordFbUserLogin: (str) => dispatch(recordFbUserLogin(str)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignupModal);
