import jwtDecode from "jwt-decode";

import { SET_CURRENT_TOKEN } from "../types";

const authenInitState = {
  currToken: "",
  userRole: "",
  userEmail: "",
  firstname: "",
  lastname: "",
};

export default (state = authenInitState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_TOKEN:
      if (payload && payload.length > 0) {
        if (payload === "RESET_INIT_AUTH") {
          return {
            currToken: "",
            userRole: "",
            userEmail: "",
            firstname: "",
            lastname: "",
          };
        } else {
          const { email, role, firstname, lastname /* , exp */ } = jwtDecode(
            payload
          );
          /* email: "jack@gmail.com"
            exp: 1617173934
            firstname: "jack"
            iat: 1609397934
            iss: "henry white card school"
            joinDate: "2020-12-31T06:58:50.753Z"
            lastname: "jack"
            role: "student"
            status: "login" */
          return {
            ...state,
            currToken: payload,
            userRole: role,
            userEmail: email,
            firstname,
            lastname,
          };
        }
      } else {
        return state;
      }
    default:
      return state;
  }
};
