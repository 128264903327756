/* eslint react-hooks/exhaustive-deps: 0 */
import { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";

const AlertInfor = ({
  isShow = false,
  grimness = "error",
  msg = "No msg is passed in???",
  setShowFunc,
}) => {
  const [timeOuteId, setTimeOuteId] = useState(null);

  useEffect(() => {
    if (isShow) {
      const timeOutFuncId = setTimeout(() => {
        setShowFunc(!isShow);
      }, 10000);
      setTimeOuteId(timeOutFuncId);
    }
    return () => {
      if (timeOuteId) {
        clearTimeout(timeOuteId);
        setTimeOuteId(null);
      }
    };
  }, [isShow, setShowFunc]);

  return isShow ? (
    <Alert className="general-alert" variant="filled" severity={grimness}>
      {msg}
    </Alert>
  ) : null;
};

export default AlertInfor;
