// import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import createSagaMiddleware from 'redux-saga';

import rootReducer from "../reducers";
// import rootSaga from '../saga';

/* const sagaMidware = createSagaMiddleware();
const midWares = [thunk, sagaMidware]; */

export const configStore = () => {
  const store = createStore(
    rootReducer,
    // composeWithDevTools(applyMiddleware([...midWares])),
    // composeWithDevTools(applyMiddleware(thunk))
    applyMiddleware(thunk)
  );
  // sagaMidware.run(rootSaga);
  return store;
};
